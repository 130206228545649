
import { Component, Vue, Watch } from 'vue-property-decorator';
import Footer from '@/components/Footer.vue';
import { technologiesMap } from '@/maps/technologies/technologies';
import { ITechnology } from '@/interfaces/technology';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import { ScreenSize } from '@/interfaces/screen-size';

@Component({
    name: 'Technologies',
    components: {
        Footer,
        Carousel3d,
        Slide,
    },
})
export default class Technologies extends Vue {
    public technology: ITechnology | null = null;
    prevHtml = '<div class="w-12 h-8 ml-0 select-left-icon lg:h-16 lg:w-12"></div>';
    nextHtml = '<div class="w-12 h-8 mr-0 select-right-icon lg:h-16 lg:w-12"></div>';
    controlsVisible = true;

    created() {
        this.updateTechnology();
    }

    mounted() {
        this.onScreenSizeChanged();
    }

    @Watch('$store.state.screenSize')
    onScreenSizeChanged() {
        switch (this.$store.state.screenSize) {
            case ScreenSize.small:
                this.controlsVisible = false;
                break;
            default:
                this.controlsVisible = true;
                break;
        }
    }

    @Watch('$route')
    onRouteChanged() {
        this.updateTechnology();
    }

    updateTechnology() {
        const technology = technologiesMap[this.$route.params.id];
        if (!technology) {
            this.$router.push('/pagina-inexistenta');
        }

        this.technology = technology;
    }
}
