import { ITechnology } from '@/interfaces/technology';

export const theragunPro: ITechnology = {
    title: 'THERAGUN PRO',
    shortDescription: 'THERAGUN PRO - recuperare medicală prin terapie percutivă',
    description: '<h2>Puternic și silențios, dispozitivul de terapie percutivă inteligentă ajută la reducerea \
    durerilor musculare, la îmbunătățirea mobilității și la creșterea relaxării.</h2><br>\
    <p><span class="text-primary">Theragun PRO</span> este cel mai avansat instrument de recuperare \
    medicală prin terapie percutivă atât pentru profesioniști, cât și pentru oamenii obișnuiți. \
    Este folosit atât în programele de recuperare medicală, cât și în cele de refacere postefort. \
    Acțiunea în profunzime asupra mușchilor este cu 60% mai mare decât a celorlalte aparate pentru masaj, \
    pentru că stimulează circulația, generează căldură și eliberează chiar și cea mai profundă tensiune.</p>',
    photoUrls: ['DSC_2027.webp'],
    videos: []
};