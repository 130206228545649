import { ITechnology } from '@/interfaces/technology';

export const terapieTecarDiacare: ITechnology = {
    title: 'DIACARE 7000 - TECARE THERAPY',
    shortDescription: 'Tecar DiaCaRe 700 - dispozitiv profesional pentru terapia TECARE',
    description:'<h2>Cabinetul nostru deține unul dintre cele mai performate aparate pentru terapia \
    <span class="text-primary">TECAR</span>. <span class="text-primary">DIACARE 7000</span> este un echipament \
    medical profesional de fizioterapie, ce include tehnologia de diatermie \
    <span class="text-primary">TECAR(Transfer Capacitive Resistive Energy)</span>.</h2><br>\
    <p>Acest tip de terapie de este una dintre cele mai căutate și revoluționare soluții medicale, folosite \
    cu succes în tratamentele de fizioterapie. Funcționează prin crearea unei stimulări intense la nivel celular \
    (vindecarea printr-o modalitate rapidă și nedureroasă, care accelerează „regenerarea naturală” a țesuturilor \
    biologice, având totodată un efect antiinflamator.).</p><br>\
    <p>Este eficientă pentru tratarea: pre și postoperator în \
    ligamentoplastii, proteze și osteosinteză, durerilor/leziunilor musculare, afecțiunilor articulare acute sau \
    cronice, patologiilor coloanei vertebrale (hernie de disc, protuzii discale, lombalgii, lombosciatică etc), \
    tendinopatii, tratament postoperator în artroscopii, tratamentul traumatismelor osoase sau ligamentare, în \
    procesele inflamatorii de la nivelul joncțiunii mușchi-tendon, afecțiunilor posttraumatice (are efect analgezic, \
    antiinflamator și antidematos), luxațiilor/fracturilor.</p>',
    photoUrls: ['DSC_8566.webp'],
    videos: []
};