import { ITechnology } from '@/interfaces/technology';

export const desmotec: ITechnology = {
    title: 'Desmotec',
    shortDescription: 'Recuperare medicală cu aparatul Desmotec',
    description: '<h2>Un nou și inovativ sistem complex de pregătire și recuperare pentru \
    profesioniști care poate controla parametrii activității desfășurate în contexte \
    de formare, reabilitare și prevenire.</h2><br>\
    <p>Dispozitivele <span class="text-primary">DESMOTEC</span> pe care le avem în cabinetul MovKinetic sunt instrumente \
    high-tech, iar tehnologia folosită ajută semnificativ la consolidarea musculară, \
    tonifiere și antrenamente intense. Programele alternative de antrenament concentric / excentric \
    gestionate cu ajutorul dispozitivelor <span class="text-primary">DESMOTEC</span> nu îmbunătățesc pur și simplu puterea, ci și \
    controlul elasticității și al mișcării neuromotorii. Utilizarea regulată reduce substanțial \
    leziunea musculară.</p><br>\
    <p>Echipamentele <span class="text-primary">DESMOTEC</span> sunt ideale nu numai pentru \
    sportivi de performanță ci și de antrenori sau chiar kinetoterapeuți.</p>',
    photoUrls: ['2.jpg'],
    videos: []
};