import { ITechnology } from '@/interfaces/technology';

export const fisiolineLumixLaser: ITechnology = {
    title: 'FisLaser înaltă frecvență',
    shortDescription: 'Fisioline Lumix - Dispozitiv fizioterapie cu laser înaltă frecvență',
    description: '<h2>FisLaser <span class="text-primary">MovKinetic</span> folosesc terapia cu laser de înaltă \
    frecvență <span class="text-primary">Fisioline Lumix C.P.S 7w</span> care permite pacientului să maximizeze \
    rezultatele terapeutice și să grăbească procesul de vindecare. Este o procedură sterilă și nedureroasă, \
    care are efect analgezic puternic pentru diferite afecțiuni ale țesuturilor. Creșterea energiei celulare, \
    vindecarea rapidă a țesuturilor traumatizate, îmbunătățirea metabolismului celular și creșterea fluxului \
    sangvin la nivelul zonei afectate sunt principalele efecte pe termen lung pe care această procedură le abordează.</h2><br>\
    <p>Această terapie are la bază emisiile de laser, având efect terapeutic puternic, eliberând beta-endorfinele \
    și îmbunătățind fluxul sangvin al zonei afectate</p><br>\
    <p>Laserterapia este indicată pentru următoarele afecțiuni: dureri musculare, hernie de disc, dureri de spate, \
    leziuni ale cartilajelor, artroză, traumatisme sportive, tendinite, fasciite plantare, fracturi, luxații, \
    leziuni musculare etc.</p>',
    photoUrls: ['DSC_4167.webp'],
    videos: []
};