import { ITechnology } from '@/interfaces/technology';

export const gameReady: ITechnology = {
    title: 'Game ready',
    shortDescription: 'Game Ready - Dispozitiv de crioterapie',
    description: '<h2><span class="text-primary">Game Ready GRPro 2.1</span> este un sistem inovator de recuperare prin \
    criocompresie, care ajută oamenii să se recupereze și să își revină după o accidentare sau o intervenție \
    chirurgicală. Mii de pacienți, sportivi de elită, echipe profesioniste, chirurgi ortopezi, medici \
    specialiști de medicină sportivă, kinetoterapeuți din întreaga lume au ales \
    <span class="text-primary">Game Ready</span> și îl folosesc de peste 15 ani.</h2><br>\
    <p>Sistemul oferă o răcire mai rapidă, mai profundă și mai durabilă în comparație cu metodele \
    tradiționale <span class="text-primary">Rest-Ice-Compression-Elevation (RICE)</span> și alte dispozitive</p><br>\
    <p>Pentru leziuni acute și recuperare postoperatorie, sistemul de răcire și compresie \
    <span class="text-primary">GRPro® 2.1</span> este dovedit clinic că ajută la reducerea consumului de \
    opioide, la scăderea durerii și a inflamațiilor. În plus, poate contribui pozitiv la starea mentală a \
    pacientului în timpul procesului de recuperare.</p>',
    photoUrls: ['DSC_1997.webp'],
    videos: []
};