import { ITechnology } from '@/interfaces/technology';
import { desmotec } from './desmotec';
import { gameReady } from './game-ready';
import { theragunPro } from './theragun-pro';
import { terapieTecarDiacare } from './terapie-tecar-diacare';
import { kineoIntelligent } from './kineo-intelligent';
import { laserInaltaFrecventa } from './laser-inalta-frecventa';
import { fisiolineLumixLaser } from './fisioline-lumix-laser';
import { thealTherapyByMectronic } from './theal-therapy-by-mectronic';

export const technologiesMap: { [technologyId: string]: ITechnology } = {
    desmotec,
    'game-ready': gameReady,
    'theragun-pro': theragunPro,
    'terapie-tecar-diacare': terapieTecarDiacare,
    'kineo-intelligent': kineoIntelligent,
    'laser-inalta-frecventa': laserInaltaFrecventa,
    'theal-therapy-by-mectronic': thealTherapyByMectronic,
    'fisioline-lumix-laser': fisiolineLumixLaser
};