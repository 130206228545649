import { ITechnology } from '@/interfaces/technology';

export const kineoIntelligent: ITechnology = {
    title: 'Kineo intelligent Load',
    shortDescription: 'KINEO 7000 Multistation EPS și Kineo leg press',
    description:'<h2><span class="text-primary">KINEO 7000 Multistation EPS</span> și <span class="text-primary">Kineo leg press</span> sunt \
    stații robotice de testare, evaluare, recuperare și antrenament excentric și concetric, tip platformă, ce pot pregăti sistematic și \
    detaliat fiecare mușchi al corpului uman. Platformele permit sportivilor sau pacienților să lucreze în mod eficient și precis, \
    respectând fiziologia naturală a corpului.</h2>\
    <p><h3 class="font-bold pt-8 pb-2"><span class="text-primary">ANTRENAMENT BIFAZIC EXCENTRIC/CONCENTRIC</span></h3></p>\
    <p><span class="text-primary">Kineo</span> vă permite să aplicați sarcini diferite între faza concentrică și cea excentrică \
    pentru un antrenament real, funcțional și eficient. Această funcție a dispozitivului este foarte eficientă în procesul de \
    recuperare posttrauma, dar și în dezvoltarea musculară a sportivilor de performanță.</p>\
    <p><h3 class="font-bold pt-8 pb-2"><span class="text-primary">EVALUAREA ISOKINETICĂ ȘI ISOMETRICĂ ÎN LANŢ CINETIC DESCHIS ŞI ÎNCHIS</span></h3></p>\
    <p>Cu <span class="text-primary">Kineo</span>, puteți realiza cu ușurință o evaluare  exclusiv a MEMBRELOR INFERIOARE, \
    TRUNCHIULUI și MEMBRELOR SUPERIOARE.</p>\
    <p>Efectuarea testelor sau exercițiilor în lanț cinetic deschis sau închis permite o evaluare  exclusiv, în siguranță maximă, \
    încă din primele faze postchirurgicale  sau posttraumatice.</p>\
    <p>Rapoartele detaliate despre forță, dezechilibre musculare și toate datele necesare monitorizării continue a procesului de \
    vindecare sunt vizibile instantaneu, imprimabile sau exportabile în format Excel.\
    <p><h3 class="font-bold pt-8 pb-2"><span class="text-primary">ANTRENAMENT "ÎN" APĂ</span></h3></p>\
    <P>Funcția <span class="text-primary">Exclusive SMART VISCOUS</SPAN> vă permite să reproduceți antrenamentul în apă, \
    simulând efectele reabilitării acvatice. Kineo Intelligent Load ajustează sarcina instant, în funcție de posibilitățile \
    reale ale pacientului, permițând efectuarea mișcărilor în siguranță și fără durere. Ideal pentru patologiile dureroase \
    și faza acută de reabilitare funcțională.</p>\
    <p><h3 class="font-bold pt-8 pb-2"><span class="text-primary">ÎNCĂRCARE VARIABILĂ</span></h3></p>\
    <p><span class="text-primary">Kineo</span> permite creearea unei curbe de încărcare pe baza nevoilor specifice ale pacientului \
    pentru a crește sarcina (încarcarea) în unghiurile cele mai favorabile și pentru a ușura  sarcina(încărcarea) în punctele critice (dureroase).</p>\
    <p><h3 class="font-bold pt-8 pb-2"><span class="text-primary">Testele KINEO 7000 Multistation EPS și Kineo leg press</span></h3></p>\
    <p><h3 class="pb-2"><span class="text-primary">ISOKINETIC MAX STRENGTH BALANCE ASSESSMENT - Test de rezistență și echilibru izokinetice</span></h3></p>\
    <p>Test de rezistență izokinetică pentru a arăta deficitul de rezistență între membre (sănătos și rănit). \
    Execuție multiviteză în aceeași configurare - standardul perfect pentru evaluarea rezistenței.</p>\
    <p><h5 class="pt-6 pb-2"><span class="text-primary">ECCENTRIC STRENGTH EVALUATION - Testarea izokinetică a forței excentrice</span></h3></p>\
    <p>Analiza completă cu vizualizarea graficelor și curbelor între membrul stâng și membrul drept. Cel mai sigur și mai precis mod de a \
    vă testa forța excentrică.</p>\
    <p><h5 class="pt-6 pb-2"><span class="text-primary">ISOKINETIC CONC-ECC RATIO TEST - Test izokinetic pentru evaluarea capacității concentrice</span></h3></p>\
    <p>Test izokinetic pentru evaluarea capacității concentrice și excentrice a mușchiului agonist. Analiza curbelor de forță și vizualizarea deficitului \
    dintre performanțele concentrice și excentrice</p>\
    <p><h5 class="pt-6 pb-2"><span class="text-primary">EVALUAREA PUTERII MAXIME ISOMETRICE</span></h3></p>\
    <p>Test de rezistență maximă izometrică multiunghi în aceeași sesiune. Vizualizare grafică și analiză a deficitului de forță între membrele stâng și drept.</p>\
    <p><h5 class="pt-6 pb-2"><span class="text-primary">TEST DE PUTERE</span></h3></p>\
    <p>Evaluază puterea în fața diferitelor sarcini.</p>\
    <p>Vizualizare grafică a profilului viteză-putere, legat de sarcinile aplicate.</p>\
    <p>Rapid, personalizabil, eficient.</p>',
    photoUrls: ['DSC_1737.webp', 'DSC_1818.webp', 'DSC_1928.webp'],
    videos: []
};